$primaryfamily: "Noto Sans", sans-serif;
$loginfontcolor: #eee;

//line-height
$labelLineHeight: 1.44;
$alertMsgLineHeight: 1.64;

//fonts
$fs18: 18px;
$fs16: 16px;
$fs14: 14px;

//fonts-weight
$fwbold: bold;
$fw600: 600;
$fw400: 400;

// colors
$primaryColor: #2126c4;
$black: #000;
$subText: #6e6e6e;
$labelColor: #444;
$tableHeaderColor: #f3f4ff;
$blueHeaderColor: #343682;
