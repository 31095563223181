@media only screen and (min-width: 320px) and (max-width: 576.98px) {
  .ant-drawer-content-wrapper {
    width: 90% !important;
  }
  .fluid-padding {
    padding: 0 20px;
  }
  .Logo_2 {
    width: 50%;
    height: auto;
    margin-bottom: 25px;
  }
  span.sub-content {
    font-size: 24px;
    margin-top: 45px;
  }
  .forget-password-wrapper {
    padding-top: 100px;
  }
  .form-section-content {
    height: 100vh;
    width: 90%;
    margin: 0 auto;
  }
  .forget-password-page .content .page-heading {
    font-size: 24px;
  }
  .login-content {
    font-size: 14px;
    width: 100%;
  }
  .forgot-pwd-heading h3 {
    font-size: 24px;
    margin-top: 26px;
  }
  .image-section {
    display: none;
  }
  .associates-wrapper {
    margin-top: 10px;
  }
  .list-heading {
    font-size: 22px;
    margin: 0;
  }
  .sub-heading {
    font-size: 12px;
    margin: 0 0 0 0;
  }
  .listingpage .FormSelect_css {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .listingpage .form-control-div {
    width: 100%;
    margin: 0 0 15px 0;
    margin-right: 0 !important;
  }
  .left-section .filter-form input {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  a.clear-text {
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .listingpage .invite-button-css {
    padding: 6px 24px;
    font-size: 13px;
  }
  .left-section .filter-form .ant-picker input {
    width: 100%;
  }
  .form.drawer-page .form-group label {
    font-size: 14px;
  }
  .drawer-page
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
    padding: 6px 16px;
  }
  .drawer-title {
    font-size: 22px;
  }
  .drawer-page .height-60 {
    height: 40px;
    padding: 6px 16px;
  }
  button.submit-button {
    font-size: 14px;
    margin-top: 10px;
  }
  .cancel-btn {
    font-size: 14px;
    margin: 18px auto 0;
  }
  .page-back-title h5 {
    font-size: 22px;
  }
  .profile-page .img {
    width: 50px;
  }
  .user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 35px 0;
  }
  .profile-title-name h2 {
    font-size: 18px;
    margin: 0;
  }
  .profile-title-name p {
    font-size: 12px;
  }
  .change-pwd-button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .personal-info-title h5 {
    font-size: 16px;
  }
  .inner-padding {
    padding: 20px 20px 0;
  }
  .profile-page .info {
    margin: 0 0 20px 0;
  }
  .my-profile-wrapper input:disabled {
    font-size: 16px;
  }
  .device-dtl h5 {
    font-size: 16px;
  }
  .gender-select.ant-select-disabled
    .ant-select-selector
    span.ant-select-selection-item {
    font-size: 16px;
  }
  .super-admin .circle {
    width: 50px;
    height: 50px;
    padding: 12px 10px;
  }
  .namecss h6 {
    font-size: 20px;
  }
  .user-detail-top {
    margin: 10px 0 0 0;
  }
  .user-detail-top1 ul {
    flex-wrap: wrap;
  }
  .user-detail-top1 ul li {
    width: 100%;
    margin: 0 0 22px 0;
  }
  .super-admin .txt-packer {
    font-size: 14px;
  }
  .seperator {
    margin: 0px 10px;
  }
  .user-dtl ul {
    padding: 0;
    li {
      padding: 0 20px 0 0;
    }
  }
  .super-admin .gender {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .super-admin .age {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .super-admin .money {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .super-admin .device-ID {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .super-admin .device-ID-value {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .mob-center {
    align-items: center;
  }
  .invite-drawer .ant-drawer-header {
    padding: 10px 24px;
  }
  .form.drawer-page .form-group {
    margin: 0 0 20px 0;
  }
  input.form-control {
    font-size: 14px;
    max-width: 100%;
    height: 40px !important;
  }
  .gender-select {
    height: 40px;
    .ant-select-selector {
      height: 38px !important;
    }
  }
  .profile-page
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    font-size: 14px;
    padding: 14px 12px 8px 12px;
  }
  input::placeholder {
    font-size: 12px;
    font-weight: normal;
  }
  .forget-password-page .content p.sub-heading {
    font-size: 14px;
    line-height: 20px;
  }
  .form_container .back-button .backArrow {
    font-size: 14px;
  }
  .risk-status-col {
    margin: 40px 0 0 0;
  }
  .risk-status-content img {
    width: 100%;
  }
  .admin-red-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-yellow-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-green-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-grey-na {
    width: 90px;
    font-size: 12px;
  }
  .heading-section.freq-column {
    flex-direction: column;
    align-items: flex-start;
  }
  .user-detail-sub-heading {
    margin: 0px 0 20px 0px;
  }
  .user-detail-datepicker {
    width: 100%;
  }

  .for-mob-view {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  .for-mob-view .namecss {
    margin: 0 0 0 20px;
    display: block;
  }
  .namecss.for-web {
    display: none;
  }
  .super-admin .seperator-1 {
    display: none;
  }
  .user-dtl ul li:last-child {
    padding: 0 0 0 0;
  }
  .left-section .filter-form .ant-picker .ant-picker-suffix {
    margin-right: 6px !important;
  }
  .risk-status-content {
    height: 300px;
  }
  .graph-range {
    padding: 0 30px;
    margin: -12px 0 0 0;
  }
  .graph-total h2 {
    font-size: 40px;
  }
  .resend-email-text p {
    font-size: 14px;
  }
  .copy-right .Copyright {
    font-size: 11px;
  }
  .icon-btn {
    margin: 0 0 0 10px;
    width: 12px;
  }
  span.role {
    font-size: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .graph-range {
    padding: 0 53px;
  }
  .resend-email-text p {
    font-size: 14px;
  }
  .icon-btn {
    margin: 0 0 0 10px;
    width: 12px;
  }
  span.role {
    font-size: 8px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 767.98px) {
  .image-section {
    display: none;
  }
  .fluid-padding {
    padding: 0 40px;
  }
  .listingpage .FormSelect_css {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .listingpage .form-control-div {
    width: 100%;
    margin: 0 0 15px 0;
    margin-right: 0 !important;
  }
  .left-section .filter-form input {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  button.submit-button {
    font-size: 16px;
  }
  .drawer-page
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }
  .drawer-page .height-60 {
    height: 50px;
  }
  .page-back-title h5 {
    font-size: 24px;
  }
  .profile-page .img {
    width: 75px;
  }
  .profile-title-name h2 {
    font-size: 20px;
    margin: 0;
  }
  .profile-title-name p {
    font-size: 14px;
  }
  .admin-red-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-yellow-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-green-risk {
    width: 90px;
    font-size: 12px;
  }
  .admin-grey-na {
    width: 90px;
    font-size: 12px;
  }
  .user-dtl ul li:last-child {
    padding: 0 0 0 0;
  }
  .super-admin .circle {
    width: 50px;
    height: 50px;
    padding: 10px 10px;
  }
  .namecss h6 {
    font-size: 20px;
  }
  .super-admin .txt-packer {
    font-size: 14px;
    line-height: 16px;
  }
  .user-detail-top1 ul {
    flex-wrap: wrap;
  }
  .user-detail-top1 ul li {
    width: 50%;
    margin: 0 0 22px 0;
  }
  .job-icon img {
    width: 40px;
  }
  .company-location-email-icon img {
    width: 40px;
  }
  .user-detail-top {
    margin: 0 0 0 15px;
  }
  .left-section .filter-form .ant-picker .ant-picker-suffix {
    margin-right: 6px !important;
  }
  .graph-total {
    top: 120px;
  }
  .human-img {
    top: -50px;
  }
  .human-img img {
    width: 90px;
  }
  .graph-range {
    padding: 0 65px;
  }
  .resend-email-text p {
    font-size: 14px;
  }
  .copy-right .Copyright {
    font-size: 11px;
  }
  .icon-btn {
    margin: 0 0 0 10px;
    width: 12px;
  }
  span.role {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px) {
  .image-section {
    display: none;
  }
  .fluid-padding {
    padding: 0 40px;
  }
  .list-heading {
    font-size: 24px;
  }
  .listingpage .left-section .filter-form {
    width: 100%;
    justify-content: flex-start;
  }
  .listingpage .FormSelect_css {
    width: 30%;
    margin: 0 10px 15px 0;
  }
  .listingpage .form-control-div {
    width: 30%;
    margin-right: 10px !important;
  }
  .left-section .filter-form input {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .listingpage .invite-button-css {
    margin: 0 0 0 20px;
    font-size: 14px;
  }
  .left-section .filter-form .ant-picker input {
    width: 100%;
    padding: 10px 7px;
  }
  button.submit-button {
    font-size: 18px;
  }
  .profile-title-name h2 {
    font-size: 24px;
    margin: 0;
  }
  .profile-page .info {
    margin: 0 0 20px 0;
  }
  .personal-info {
    padding: 8px 18px;
  }
  .personal-info-title h5 {
    font-size: 16px;
    line-height: 24px;
  }
  .my-profile-wrapper input:disabled {
    font-size: 14px;
  }
  .gender-select.ant-select-disabled
    .ant-select-selector
    span.ant-select-selection-item {
    font-size: 14px;
  }
  .page-back-title h5 {
    font-size: 24px;
  }
  .super-admin .circle {
    width: 70px;
    height: 70px;
  }
  .namecss h6 {
    font-size: 24px;
  }
  .user-detail-top {
    margin: 0 0 0 30px;
  }
  .user-detail-top1 ul {
    flex-wrap: wrap;
  }
  .user-detail-top1 ul li {
    width: 50%;
    margin: 0 0 22px 0;
  }
  .super-admin .txt-packer {
    font-size: 16px;
  }
  .watch-div {
    margin: 10px auto;
  }
  .img-watch {
    width: 80px;
    height: 166px;
  }
  .user-detail-sub-heading {
    margin: 0 0 20px 0px;
  }
  .super-admin .txt-packer {
    font-size: 16px;
  }
  .super-admin .line {
    margin: 10px 0px 30px;
  }
  .drawer-page
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }
  .drawer-page .height-60 {
    height: 50px;
  }
  .left-section .filter-form .ant-picker .ant-picker-suffix {
    margin-right: 6px !important;
  }
  .copy-right .Copyright {
    font-size: 11px;
  }
  .icon-btn {
    margin: 0 0 0 10px;
    width: 12px;
  }
  span.role {
    font-size: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  // .image-section {
  //   display: none;
  // }
  .fluid-padding {
    padding: 0 30px;
  }
  .listingpage .FormSelect_css {
    width: 30%;
    margin: 0 14px 15px 0;
  }
  .listingpage .form-control-div {
    width: 30%;
  }
  .left-section .filter-form input {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .left-section .filter-form .search-bar {
    margin: 0 12px 0 15px;
    display: flex;
    width: 30%;
  }
  .listingpage .invite-button-css {
    margin: 0 0 0 20px;
  }
  .left-section .filter-form .ant-picker input {
    width: 100%;
  }
  .image-section-content {
    padding: 40px 20px;
  }
  .heading-sub {
    line-height: 24px;
  }
  .heading {
    font-size: 32px;
    line-height: 40px;
  }
  .login-content {
    width: 100%;
  }
  .user-detail-top {
    margin: 0 0 0 50px;
  }
  .user-detail-top1 ul {
    flex-wrap: wrap;
  }
  .user-detail-top1 ul li {
    width: 50%;
    margin: 0 0 20px 0;
  }
  .super-admin .circle {
    width: 100px;
    height: 100px;
  }
  .super-admin .line {
    margin: 10px 0px 30px;
  }
  .my-profile-wrapper input:disabled {
    font-size: 16px;
  }
  .device-dtl h5 {
    font-size: 16px;
  }
  .gender-select.ant-select-disabled
    .ant-select-selector
    span.ant-select-selection-item {
    font-size: 16px;
  }
  .icon-btn {
    margin: 0 0 0 10px;
    width: 12px;
  }
  span.role {
    font-size: 8px;
  }
}

@media (min-width: 1200px) and (max-width: 1299.98px) {
  .fluid-padding {
    padding: 0 70px;
  }
  .list-heading {
    font-size: 26px;
  }
  .namecss h6 {
    font-size: 22px;
  }
  .super-admin .txt-job-role {
    font-size: 13px;
  }
  .super-admin .txt-packer {
    font-size: 16px;
    line-height: 13px;
    margin: 4px 0 0 0;
  }
  .page-back-title h5 {
    font-size: 22px;
  }
  .profile-title-name h2 {
    font-size: 20px;
  }
  .profile-title-name p {
    font-size: 14px;
  }
  .my-profile-wrapper .profile-info-title {
    font-size: 14px;
  }
  .my-profile-wrapper input:disabled {
    font-size: 16px;
  }
  .font-blue {
    font-size: 16px;
  }
  button.submit-button {
    padding: 10px 45px;
    font-size: 14px;
  }
  .cancel-btn {
    font-size: 14px;
  }
  .drawer-title {
    font-size: 22px;
  }
  .form.drawer-page .form-group label {
    font-size: 14px;
  }
  .list-heading {
    font-size: 22px;
  }
  .sub-heading {
    font-size: 12px;
  }
  .form_container .back-button .backArrow {
    font-size: 14px;
  }
  .forget-password-page .content .page-heading {
    font-size: 22px;
  }
  .forget-password-page .content p.sub-heading {
    font-size: 14px;
  }
  .invalid-input {
    font-size: 12px;
  }
  .listingpage .invite-button-css {
    padding: 8px 16px;
    font-size: 14px;
  }
  .btn-edit img {
    width: 15px;
  }
  .super-admin .circle {
    width: 80px;
    height: 80px;
  }
  .listingpage .FormSelect_css {
    width: 340px;
  }
  .left-section .filter-form input {
    width: 340px;
  }
  .left-section .filter-form .search-bar {
    margin-right: 13px;
  }
  .drawer-page .height-60 {
    height: 50px;
  }
  .drawer-page
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }
  .graph-total {
    h6 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
    h2 {
      font-size: 36px;
    }
  }
  .graph-range {
    padding: 0 26px;
    margin: -10px 0 0 0;
  }
  .icon-btn {
    width: 12px;
    margin: 0 0 0 8px;
    svg {
      width: 14px;
    }
  }
  span.role {
    font-size: 8px;
    margin: 0 0 0 6px;
  }
  .listingpage .FormSelect_css {
    width: 300px;
    margin: 0 12px 0 0;
  }
}

@media (min-width: 1300px) and (max-width: 1399.98px) {
  .namecss h6 {
    font-size: 22px;
  }
  .super-admin .txt-job-role {
    font-size: 13px;
  }
  .super-admin .txt-packer {
    font-size: 16px;
  }
  .page-back-title h5 {
    font-size: 22px;
  }
  .profile-title-name h2 {
    font-size: 20px;
  }
  .profile-title-name p {
    font-size: 14px;
  }
  .my-profile-wrapper .profile-info-title {
    font-size: 14px;
  }
  .my-profile-wrapper input:disabled {
    font-size: 16px;
  }
  .font-blue {
    font-size: 16px;
  }
  button.submit-button {
    padding: 10px 45px;
    font-size: 14px;
  }
  .cancel-btn {
    font-size: 14px;
  }
  .drawer-title {
    font-size: 22px;
  }
  .form.drawer-page .form-group label {
    font-size: 14px;
  }
  .list-heading {
    font-size: 22px;
  }
  .sub-heading {
    font-size: 12px;
  }
  .form_container .back-button .backArrow {
    font-size: 14px;
  }
  .forget-password-page .content .page-heading {
    font-size: 22px;
  }
  .forget-password-page .content p.sub-heading {
    font-size: 14px;
  }
  .invalid-input {
    font-size: 12px;
  }
  .listingpage .invite-button-css {
    padding: 8px 16px;
    font-size: 14px;
  }
  .btn-edit img {
    width: 15px;
  }
  .graph-total {
    h6 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
    h2 {
      font-size: 40px;
    }
  }
  .graph-range {
    padding: 0 28px;
    margin: -10px 0 0 0;
  }
  .icon-btn {
    width: 12px;
    margin: 0 0 0 8px;
    svg {
      width: 14px;
    }
  }
  span.role {
    font-size: 8px;
    margin: 0 0 0 6px;
  }
}

@media (min-width: 1401px) and (max-width: 1599.98px) {
  .namecss h6 {
    font-size: 22px;
  }
  .super-admin .txt-job-role {
    font-size: 13px;
  }
  .super-admin .txt-packer {
    font-size: 16px;
  }
  .page-back-title h5 {
    font-size: 22px;
  }
  .profile-title-name h2 {
    font-size: 20px;
  }
  .profile-title-name p {
    font-size: 14px;
  }
  .my-profile-wrapper .profile-info-title {
    font-size: 14px;
  }
  .my-profile-wrapper input:disabled {
    font-size: 16px;
  }
  .font-blue {
    font-size: 16px;
  }
  button.submit-button {
    padding: 10px 45px;
    font-size: 14px;
  }
  .cancel-btn {
    font-size: 14px;
  }
  .drawer-title {
    font-size: 22px;
  }
  .form.drawer-page .form-group label {
    font-size: 14px;
  }
  .list-heading {
    font-size: 22px;
  }
  .sub-heading {
    font-size: 12px;
  }
  .form_container .back-button .backArrow {
    font-size: 14px;
  }
  .forget-password-page .content .page-heading {
    font-size: 22px;
  }
  .forget-password-page .content p.sub-heading {
    font-size: 14px;
  }
  .invalid-input {
    font-size: 12px;
  }
  .listingpage .invite-button-css {
    padding: 8px 16px;
    font-size: 14px;
  }
  .btn-edit img {
    width: 15px;
  }
  .super-admin .circle {
    width: 100px;
    height: 100px;
  }
  .graph-range {
    padding: 0 30px;
    margin: -10px 0 0 0;
  }
  .graph-total h2 {
    font-size: 40px;
  }
  .icon-btn {
    width: 12px;
    svg {
      width: 16px;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1899.98px) {
  .graph-range {
    padding: 0 40px;
    margin: -10px 0 0 0;
  }
  .icon-btn {
    width: 12px;
  }
}

@media (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}
